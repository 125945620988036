import { Box, Grid, makeStyles, Slide } from "@material-ui/core";
import React from "react";
import NewAccountDialog from "../../../Dialogs/NewAccountDialog";

const useStyles = makeStyles((theme) => ({
  highlights: {
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
}));

export default function Highlights() {
  const content = require("../en.json");
  const classes = useStyles();
  const [getStarted, setGetStarted] = React.useState(false);

  const getStartedDialog = getStarted && (
    <NewAccountDialog reset={setGetStarted} />
  );

  return (
    <Grid container direction="column">
      {getStartedDialog}
      <Grid item>
        {content.intro.highlights.map((item, index) => (
          <Slide
            timeout={900 * (index + 1)}
            in={true}
            direction="right"
            mountOnEnter
            key={`highlight-slide-${index}`}
          >
            <h2 key={`highlight-${index}`} className={classes.highlights}>
              {item}
            </h2>
          </Slide>
        ))}
      </Grid>
      <Grid item>
        <Box
          display={{ xs: "none", sm: "block" }}
          style={{ textAlign: "left" }}
        >
          <input
            onClick={() => setGetStarted(true)}
            type="submit"
            className="btn-primary submit"
            value="Get Started"
          />
        </Box>
      </Grid>
    </Grid>
  );
}
