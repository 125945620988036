import React from "react";
import ContactUs from "../ContactUs";
import Advantages from "./components/Advantages";
import Features from "./components/Features";
import Integrations from "./components/Integrations";
import Intro from "./components/Intro";
import Pricing from "./components/Pricing";

export default function Home() {
  return (
    <div style={{ marginTop: "70px" }}>
      <Intro />
      <Advantages />
      <Features />
      <Integrations/>
      <Pricing />
      <ContactUs/>
    </div>
  );
}
