import React from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { useHistory, useLocation } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import MobileDrawer from "./components/MobileDrawer";
import NewAccountDialog from "../Dialogs/NewAccountDialog";
import { scroller } from "react-scroll";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px",
  },
  grow: {
    flexGrow: 1,
  },
  logo: {
    width: "180px",
    padding: "2px",
  },
  appBar: {
    color: "#000000",
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderColor: "black",
    boxShadow: "none",
  },
  appBarTrans: {
    color: "#000000",
    backgroundColor: "rgba(255, 255, 255, 1)",
    boxShadow: "none",
  },
  appBarAway: {
    color: "#000000",
    background: "rgba(248, 247, 216, 0.7)",
  },
  logoMobile: {
    maxWidth: 120,
    padding: "0",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    fontWeight: "300",
    fontSize: "20px",
    fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function Navigation() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [getStarted, setGetStarted] = React.useState(false);

  const menuTabs = require("./en.json");
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {}, [location]);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = isMobileMenuOpen && (
    <MobileDrawer reset={setMobileMoreAnchorEl} getStarted={setGetStarted} />
  );
  const scrollToSection = (item) => {
    if (location.pathname.includes("forms")) {
      history.push("/");
    }
    scroller.scrollTo(item, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };
  const NewAccount = getStarted && <NewAccountDialog reset={setGetStarted} />;
  return (
    <div className={classes.grow}>
      <AppBar
        className={classes.appBar}
        // style={location.pathname==="/" ||location.pathname==="/New-Church-Project"? null:{background: "rgba(248, 247, 216)"}}
        position={"fixed"}
      >
        <Toolbar>
          <IconButton
            onClick={() => history.push("/")}
            style={{ borderRadius: "0%" }}
            classes={{ root: classes.root }}
          >
            <img style={{maxHeight:"50px"}} src={`${process.env.PUBLIC_URL}/images/jamfree_logo_v1.jpg`} />
          </IconButton>
          <div className={classes.search}></div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {menuTabs.menu.map(({name, cssClass, onHover, url, external}, index) =>
              location.pathname.includes("/forms") ? null : (
                <li
                  key={index}
                  onMouseOver={null}
                  onClick={() =>
                    external
                      ? window.open(url)
                      : scrollToSection(cssClass)
                  }
                  color="inherit"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    listStyle: "none",
                    borderRadius: "0%",
                  }}
                >
                  <a className={classes.title}>{name}</a>
                </li>
              )
            )}
            <li style={{ listStyle: "none" }}>
              <a onClick={() => setGetStarted(true)} className="btn-primary">
                Get Started
              </a>
            </li>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {NewAccount}
      {renderMobileMenu}
    </div>
  );
}
