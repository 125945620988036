import { Box, Container, Grid } from "@material-ui/core";
import React from "react";
import NewAccountDialog from "../../../Dialogs/NewAccountDialog";
import Highlights from "./Highlights";
import MainHomeImage from "./MainHomeImage";

export default function Intro() {
  const [getStarted, setGetStarted] = React.useState(false);
  const getStartedDialog = getStarted && (
    <NewAccountDialog reset={setGetStarted} />
  );
  return (
    <Container maxWidth="lg">
      {getStartedDialog}
      <Grid
        style={{ paddingTop: "40px", paddingBottom: "40px" }}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <Highlights />
        </Grid>
        <Grid item>
          <MainHomeImage />
        </Grid>
      </Grid>
      <Grid item>
        <Box display={{ xs: "block", sm: "none" }}>
          <input
            onClick={() => setGetStarted(true)}
            type="submit"
            className="btn-primary submit"
            value="Get Started"
          />
        </Box>
      </Grid>
    </Container>
  );
}
