import { Container, Divider, Grid, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Phone } from "@material-ui/icons";
import SendIcon from '@material-ui/icons/Send';
import React from "react";

export default function ContactUs() {
  return (
    <div className='contact-us' style={{marginTop:"20px", marginBottom:"20px"}}>
      <Divider />
      <Container maxWidth="lg">
        <Grid container direction="row" justify='center' alignItems='center'>
          <Grid item xs={12} md={4}>
            <ListItem>
              <ListItemIcon>
                <SendIcon />
              </ListItemIcon>
              <ListItemText primary={<div className='contact-nav-item'>jamfree@gmail.com</div>} />
            </ListItem>
          </Grid>
          <Grid item xs={12} md={4}>
          <ListItem>
              <ListItemIcon>
                <Phone />
              </ListItemIcon>
              <ListItemText primary="Sent mail" />
            </ListItem>
          </Grid>
        </Grid>
      </Container>
      <Divider />
    </div>
  );
}
