import { Grid, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "300px",
  },
  imageContainer: {
    width: "100px",
    height: "100px",
    margin: "auto",
  },
}));
export default function AdvantageContent(props) {
  const { title, description, src } = props;
  const classes = useStyles();

  return (
    <Grid
      className={classes.container}
      container
      direction="column"
      justify="center"
    >
      <Grid item>
        <div className={classes.imageContainer}>
          <img width={"100%"} src={process.env.PUBLIC_URL + `/images/${src}`} />
        </div>
      </Grid>
      <Grid item>
        <h3>{title}</h3>
      </Grid>
      <Grid>
        <p>{description}</p>
      </Grid>
    </Grid>
  );
}
