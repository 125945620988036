import { Container, Grid } from "@material-ui/core";
import React, { Component } from "react";
import { withRouter } from "react-router";
import api from "../api";
import FormBuilder from "../Forms/FormBuilder";
import SuccessMessage from "../Forms/FormBuilder/SuccessMessage";
import CustomBackdrop from "./components/CustomBackdrop";
import Alerts from "../Alerts";
import FormNotFound from "./components/FormNotFound";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

class JamfreeForms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      errorStatusCode: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.resetSubmit = this.resetSubmit.bind(this)
  }

  componentDidMount() {
    const testData = require("../Forms/en.json");
    const account_id = this.props.match.params.account_id;
    const form_id = this.props.match.params.form_id;
    // const account_id = testData.test.account_id;
    // const form_id = testData.test.form_id;

    console.log("params: ", account_id, form_id);
    this.setState({ loading: true });
    api
      .getForm(form_id)
      .then((data) => {
        this.setState({
          fields: data.fields,
          formTitle: data.title,
          formDescription: data.description,
          formMedia: data.medias[0],
          loading: false,
        });
      })
      .catch((err) => {
        alert(err)

        this.setState({
          errorStatusCode: err.response.status,
          loading: false,
          error: true,
        });
      });
    this.setState({ account_id: account_id, form_id: form_id });
  }
  resetSubmit(){
    this.setState({submitted: false})
  }
  onSubmit(data) {
    this.setState({ loading: true });
    let entry = {
      form_id: parseInt(this.state.form_id),
      submission: data,
    };
    console.log("entry: ", entry);
    api
      .submitEntry(entry)
      .then((response) => {
        this.setState({ loading: false, submitted: true });
      })
      .catch((err) => {
        let error = err.response.data.error;
        let newState = {};
        if (error) {
          newState["errorMessage"] = error.message;
          newState["error"] = true;
        }
        newState["loading"] = false;
        this.setState(newState);
      });
  }
  render() {
    const {
      fields,
      formTitle,
      formDescription,
      submitted,
      formMedia,
      loading,
      error,
      errorMessage,
      errorStatusCode,
    } = this.state;

    const loader = loading && <CustomBackdrop />;
    const errorBanner = error && (
      <Alerts severity={"warning"} message={errorMessage} />
    );

    const formNotFound = error && errorStatusCode === 404 && <FormNotFound />;
    console.log(errorStatusCode);

    return (
      <div className="jamfree-form">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {formNotFound}
        {loader}
        {submitted ? (
          <SuccessMessage resetSubmitted={this.resetSubmit} />
        ) : (
          <Container maxWidth="lg">
            <Grid container direction="column">
              <Grid item>
                {fields ? (
                  <FormBuilder
                    banner={errorBanner}
                    description={formDescription}
                    onSubmit={this.onSubmit}
                    title={formTitle}
                    fields={fields}
                    media={formMedia}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Container>
        )}
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}
export default withRouter(JamfreeForms);
