import React from "react";
import {
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import FormDivider from "../components/FormDivider";
import ReactHookFormSelect from "../components/ReactHookFormSelect";
import ReactHookTextField from "../components/ReactHookTextField";
import ReactHookRadio from "../components/ReactHookRadio";
import ReactHookCheckBox from "../components/ReactHookCheckBox";
import ReactHookDatePicker from "../components/ReactHookDatePicker";

const FormBuilder = ({ title, description, fields, onSubmit, media, banner }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = React.useState(false);

  return (
    <Paper className="form-paper" elevation={3}>
      {banner}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          style={{ maxWidth: "500px", margin: "auto" }}
          direction="row"
        >
          {media ? (
            <Grid item xs={12} md={12}>
              <img width="300px" src={media.src} />
            </Grid>
          ) : (
            <Grid item xs={12} md={12}>
              <div style={{ padding: "10px", fontSize: "32px" }}>{title}</div>
            </Grid>
          )}
          {fields.map((item, index) => {
            switch (item.kind) {
              case "email":
              case "multiline":
              case "tel":
              case "text": {
                return (
                  <Grid
                    style={{ padding: "10px" }}
                    xs={item.half_size ? 6 : 12}
                    item
                    key={`field-${index}`}
                  >
                    <ReactHookTextField
                      multiline={item.kind === "multiline"}
                      email={item.kind === "email"}
                      type={item.kind}
                      name={item.name}
                      label={item.label}
                      control={control}
                      required={item.required}
                    />
                  </Grid>
                );
              }
              case "select": {
                return (
                  <Grid
                    style={{ padding: "10px" }}
                    xs={item.half_size ? 6 : 12}
                    md={12}
                    item
                    key={`field-${index}`}
                  >
                    <ReactHookFormSelect
                      fullWidth={true}
                      margin="normal"
                      label={item.label}
                      control={control}
                      name={item.name}
                      defaultValue=""
                    >
                      {item.options.map((option, subIndex) => (
                        <MenuItem
                          value={option.value}
                          key={`menu-item-${option.key}`}
                        >
                          {option.value}
                        </MenuItem>
                      ))}
                    </ReactHookFormSelect>
                  </Grid>
                );
              }
              case "divider": {
                return <FormDivider />;
              }
              case "radio": {
                return (
                  <Grid
                    item
                    xs={item.half_size ? 6 : 12}
                    key={`field-${index}`}
                  >
                    <ReactHookRadio
                      control={control}
                      label={item.label}
                      name={item.name}
                    >
                      {item.options.map((option, optionIndex) => (
                        <FormControlLabel
                          key={`radio-${optionIndex}`}
                          value={option.value}
                          label={option.content}
                          control={<Radio />}
                        />
                      ))}
                    </ReactHookRadio>
                  </Grid>
                );
              }
              case "date":{
                return (
                  <Grid
                    item
                    xs={12}
                    key={`field-${index}`}
                  >
                    <ReactHookDatePicker
                      control={control}
                      label={item.label}
                      name={item.name}
                    />
                  </Grid>
                )
              }
              case "checkbox": {
                return (
                  <Grid
                    item
                    xs={item.half_size ? 6 : 12}
                    key={`field-${index}`}
                  >
                    {item.options.map((option, optionIndex) => (
                      <ReactHookCheckBox
                        mainLabel={item.label}
                        name={option.value}
                        label={option.content}
                        control={control}
                        position={optionIndex}
                      />
                    ))}
                  </Grid>
                );
              }
            }
          })}
          <Grid item style={{ margin: "auto" }}>
            <input
              value="Submit"
              type="submit"
              className="btn-primary submit"
            />
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
export default FormBuilder;
