import { Grid, Paper, useMediaQuery, Divider, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root:{
    height:"400px"
  }
}))
const PricingCard = ({ title, description, index, price }) => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");
  return (
    <Grid
      key={`pricing-item-${index}`}
      item
      xs={matches ? 12 : 4}
      sm={6}
      md={4}
      justify="center"
    >
      <Paper square={false} classes={{root:classes.root}} style={{borderRadius:"10px"}} className="pricing-card" elevation={3}>
        <h2 className="pricing-title">{title}</h2>
        <div style={{ paddingBottom: "10px" }}>
          <Divider  />
        </div>
        <div className="plan-description">
        <p>{description}</p>
        </div>
        <Grid container direction='row' justify='center' alignItems='center'>
          <Grid className='plan-price' item>
          {price}$
          </Grid>
          <Grid item className='plan-rate'>/month</Grid>
        </Grid>
        <a className='common-UppercaseText plan-button'>
          Get Started In Minutes
        </a>
      </Paper>
    </Grid>
  );
};
export default PricingCard;
