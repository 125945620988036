import axios from "axios";
import Cookies from "js-cookie";

axios.interceptors.request.use(function (config) {
  config.headers["X-Source"] = "web"
  return config
});
const jamfreeEndpoint = "https://enigmatic-beach-93405.herokuapp.com/"
const jamfree = {
  get: (resource) => {
    return axios.get(jamfreeEndpoint+resource);
  },
  post: (resource, data) => {
    return axios.post(jamfreeEndpoint+resource, data);
  },
  put: (resource, data) => {
    return axios.put(jamfreeEndpoint+resource, data);
  },
  delete: (url, data) => {
    return axios.delete(url, data);
  },
  postMedia: (url, data) => {
    return axios.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
export default jamfree;
