import { Grid } from '@material-ui/core'
import React from 'react'

export default function Header() {
    return (
        <Grid item>
            <h2>Integrations</h2>
            <p>Improve your day-to-day tasks</p>
        </Grid>
    )
}
