const { defaults } = require("js-cookie");
const { default: requests } = require("../../utils/http")

const testEndpoint = "https://owjgungc83.execute-api.us-east-1.amazonaws.com/stripe/products";

const stripe = {
    products:() =>{
        return requests.get(testEndpoint)
    }
}

export default stripe;