import { Container, Grid } from "@material-ui/core";
import React from "react";
import FeatureList from "./FeatureList";

export default function Features() {
  const content = require("../en.json");
  return (
    <div style={{backgroundColor:"#e1e1e1", width:"100%"}}>
      <Container maxWidth='lg'  style={{backgroundColor:"e1e1e1"}}>
      <Grid
        className="jamfree-features"
        container
        direction="column"
        justify="center"
      >
        <Grid item>
          <h2>Features</h2>
        </Grid>
        <Grid item>
          <Grid container direction="row" justify='center' >
            {content.features.map(({features, title}, index) => (
              <Grid item xs={12} md={4} key={`feature-list-${index}`} >
                <h3>{title}</h3>
                <FeatureList features={features} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      </Container>
    </div>
  );
}
