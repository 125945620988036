import { makeStyles, TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";

const useStyles = makeStyles((theme, type) => ({
  textField: {
    width: 300,
    margin: 100,
  },
  //style for font size
  resize: {
    fontSize: 18,
  },
  labelRoot:{
    fontSize:20,
    [theme.breakpoints.down('sm')]:{
      fontSize:18
    }
  }
}));
const ReactHookTextField = ({
  name,
  label,
  control,
  defaultValue,
  children,
  email,
  required,
  type,
  ...props
}) => {
  const labelId = `${name}`;
  const classes = useStyles(type);
  const multiline = type === "multiline";
  return (
    <Controller
      rules={{ required: `${label} is required.` }}
      render={({ field, fieldState: { error } }) => {
        return (
          <TextField
            variant="standard"
            {...field}
            type={type}
            fullWidth
            label={label}
            name={name}
            helperText={error ? error.message : null}
            error={!!error}
            margin="normal"
            InputLabelProps={{
              classes: { root: classes.labelRoot },
              shrink: true,
            }}
            style={{textAlign:`${multiline? "left":"center"}`}}
            multiline={multiline}
            rows={multiline ? 8 : 1}
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
          />
        );
      }}
      name={name}
      control={control}
      defaultValue={defaultValue ? defaultValue : ""}
    />
  );
};
export default ReactHookTextField;
