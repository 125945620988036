import { Grid } from '@material-ui/core'
import React from 'react'

export default function Integration({ src, title }) {
    return (
        <Grid justify="center" item xs={12} md={4}>
            <div>
                <img className="integration-image" src={src} />
            </div>
        </Grid>
    )
}
