import {
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  makeStyles,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  btn: {
    display: "block",
    borderRadius: "50%",
    height: "300px",
    width: "300px",
    border: "1px solid red",
    cursor: "pointer",
  },
}));
export default function Marie() {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  const [done, setDone] = React.useState(false);

  const [openLetter, setOpenLetter] = React.useState(false);
  const content = require("./en.json").content;

  const handleChange = (event) => {
    setChecked(!checked);
  };
  const letterNotOpen = !openLetter && (
    <Grid container direction="column">
      <Grid item>
        <h2>Hi its your Birthday today ?</h2>
      </Grid>
      <Grid item>
        <FormControlLabel
          control={<Checkbox onChange={handleChange} checked={checked} />}
          label="YES"
        />
        <FormControlLabel
          control={<Checkbox onChange={handleChange} checked={!checked} />}
          label="NO"
        />
      </Grid>
      <Grid item style={{ marginTop: "20px" }}>
        {checked ? (
          <div>
            <h2>
              Well I, Mark Bastawros Would like to wish you a very Happy
              Birthday :)
            </h2>
            <button
              onClick={() => setOpenLetter(true)}
              class="glow-on-hover"
              type="button"
            >
              Open Me when you are ready!
            </button>
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
  const letterOpen = openLetter && (
    <Grid item>
      <Grid container justify="center" direction="column">
        {content.map((item, index) => (
          <Grid item key={`item-${index}`}>
            <p style={{ fontSize: "18px", maxWidth: "600px" }}>{item}</p>
          </Grid>
        ))}
        <Grid item style={{ marginTop: "20px", marginBottom: "200px" }}>
          <button
            onClick={() => {
              setDone(true);
              setOpenLetter(false);
              setChecked(false);
            }}
            class="glow-on-hover"
            type="button"
          >
            Im done :)
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
  const doneReading = done && !openLetter && !checked && (
    <Grid item>
      <img style={{maxWidth:"80%"}} src="https://i.pinimg.com/originals/3e/26/c2/3e26c2b37f1e6afdb4c4772cc544f615.png" />
      <h2> I wana make the world a better place with you by my side</h2>
    </Grid>
  );
  return (
    <Container style={{ marginTop: "70px" }} maxWidth="lg">
      <Grid
        alignContent="center"
        alignItems="center"
        container
        justify="center"
        direction="column"
      >
        {letterNotOpen}
        {letterOpen}
        {doneReading}
      </Grid>
    </Container>
  );
}
