import { Container, Grid } from "@material-ui/core";
import React from "react";
import api from "../../../api";
import PricingCard from "./components/PricingCards";

export default function Pricing() {
  const content = require("../en.json");
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    api.getProducts().then((response) => {
      setData(response.data);
    })
  }, [])
  console.log(data)
  return (
    <Container maxWidth="lg">
      <Grid container direction="column" className={"jamfree-pricing"}>
        <Grid item>
          <h2>Pricing</h2>
          <p>No setup fees or hidden fees, enjoy a 14-days free trial</p>
        </Grid>
        <Grid item>
          <Grid container direction="row" justify='center'>
            {content.pricing.map(({ title, description, price }, index) => 
              <PricingCard
                index={index}
                title={title}
                description={description}
                price={price}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
