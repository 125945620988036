import { Container } from "@material-ui/core";
import React from "react";

export default function FormNotFound() {
  return (
    <Container style={{ marginTop: "100px" }} maxWidth="lg">
      <div style={{ maxWidth: "512px", margin: "auto" }}>
        <img
          width="100%"
          src="https://jamfree-media.s3.amazonaws.com/website-images/not-found-404.png"
        />
      </div>
    </Container>
  );
}
