import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { Controller } from "react-hook-form";

const ReactHookFormSelect = ({
  name,
  label,
  control,
  defaultValue,
  children,
  required,
  ...props
}) => {
  const labelId = `${name}`;
  let rules = {}
  if (required){
    rules['required']= `${label} is required.`
  }
  console.log(name, label)

  return (
    <FormControl {...props}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        rules={rules}
        render={({ field, fieldState: { error }}) => {
          return (
            <Select required={required?true:false} error={!!error} {...field} labelId={labelId} label={label}>
              {children}
            </Select>
          );
        }}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </FormControl>
  );
};
export default ReactHookFormSelect;
