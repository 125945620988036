import { Alert } from '@material-ui/lab';
import React from 'react';

export enum Severity {
  SUCCESS = "success",
  error = "error",
}

interface AlertProps{
  message: string,
  severity: Severity,
}

export default function Alerts({message, severity}:AlertProps) {
  return (
    <div style={{padding:"10px"}}>
        <Alert severity={severity}>
          {message}
        </Alert>
      </div>
  )
}