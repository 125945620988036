import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { scroller } from "react-scroll";
import { AppBar, Button, Divider } from "@material-ui/core";
import NewAccountDialog from "../../Dialogs/NewAccountDialog";

const useStyles = makeStyles({
  list: {
    width: "100%",
  },
  appBar: {
    color: "#000000",
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderColor: "black",
    boxShadow: "none",
  },
  logo: {
    width: "200px",
  },
  fullList: {
    width: "auto",
  },
  paper: {},
});

export default function MobileDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState(true);
  const history = useHistory();
  const menuTabs = require('../en.json')

  const scrollToSection = (item) => {
    scroller.scrollTo(item, {
      duration: 1000,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const toggleDrawer = (open) => (event) => {
    props.reset(open);
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  // const getIcon = (name) => {
  //   if (name === "Facebook") {
  //     return <FacebookIcon fontSize={"large"} />;
  //   } else if (name === "YouTube") {
  //     return <YouTubeIcon fontSize={"large"} />;
  //   } else if (name === "Monthly Schedule") {
  //     return <TodayIcon fontSize={"large"} />;
  //   }
  // };

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <IconButton
        onClick={() => history.push("/")}
        style={{ borderRadius: "0%" }}
      ></IconButton>
      <List style={{ paddingTop: "0px", margin: "auto" }}>
        {menuTabs.menu.map((item, index) => (
          <div>
            <Divider variant="fullWidth" />
            <ListItem
              button
              style={{ fontWeight: "bolder", textAlign: "center" }}
              key={index}
              onClick={() =>
                item.external
                  ? window.open(item.url)
                  : scrollToSection(item.cssClass)
              }
            >
              <ListItemText
                key={`list-item-text-${index}`}
                style={{ fontWeight: "bold" }}
                primary={<a className="mobile-menu-item">{item.name}</a>}
              />
            </ListItem>
          </div>
        ))}
      </List>
      {/* <Divider />
      <List>
        {[
          {
            name: "Monthly Schedule",
            url: "https://st-joseph-media.s3.amazonaws.com/schedule/MAY.pdf",
          },
          {
            name: "Facebook",
            url:
              "https://www.facebook.com/St-Joseph-Coptic-Orthodox-church-Ottawa-New-church-building-project-2021-105978408062404/?view_public_for=105978408062404Youtube",
          },
          {
            name: "YouTube",
            url:
              "https://www.youtube.com/channel/UChA-FNHsg9VJgVlbfjUzpkw/featured",
          },
        ].map((item, index) => (
          <ListItem
            button
            key={item.name}
            onClick={() => window.open(item.url)}
          >
            <ListItemIcon>{getIcon(item.name)}</ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
      </List> */}
    </div>
  );

  return (
    <AppBar position={"fixed"} className={classes.appBar}>
      <SwipeableDrawer
        classes={{
          paper: classes.paper,
        }}
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        anchor="top"
      >
        <IconButton
          style={{ textAlign: "left", maxWidth: "50px" }}
          onClick={toggleDrawer(false)}
        >
          <CloseIcon style={{ textAlign: "left" }} />
        </IconButton>
        {list()}
        <Divider variant="fullWidth" />
        <div
          style={{
            backgroundColor: "#008060",
            padding: "10px",
            textAlign: "center",
          }}
        >
          <Button
            style={{ color: "white", padding: "10px", fontWeight: "bolder" }}
            onClick={() => props.getStarted(true)}
          >
            Get Started
          </Button>
        </div>
      </SwipeableDrawer>
    </AppBar>
  );
}
