import { Container, Grid, Link, makeStyles, Paper } from "@material-ui/core";
import { ReactSVG } from "react-svg";
import FormSignature from "../components/FormSignature";
import { ReactComponent as SuccessSvg } from "./success-submission-icon.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: "100px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "70px",
    },
  },
}));
const SuccessMessage = ({ message, image, fields, resetSubmitted }) => {
  const classes = useStyles();
  const text = require("../en.json");

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Paper className="form-submission">
        <Grid container direction="column">
          <Grid item>
            <h1>{text.submission.success.title}</h1>
          </Grid>
          <Grid item>
            <SuccessSvg />
          </Grid>
          <Grid item>
            <p>An email will be sent to you shortly.</p>
          </Grid>
          <Grid item>
            <Link onClick={() => resetSubmitted()}>Make another submission</Link>
          </Grid>
          <Grid item>
            <FormSignature />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default SuccessMessage;
