import {
  Backdrop,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import api from "../../api";
import { makeStyles } from "@material-ui/core/styles";
import { set } from "js-cookie";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function NewAccountForm() {
  const classes = useStyles();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [verifyPassword, setVerifyPassword] = React.useState(false);
  const [errorPassword, setErrorPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const onSubmit = (data) => {
    if (data.password !== data.verify_password) {
      errors.password = true;
      setErrorPassword("Password's dont match.");
    } else {
      delete data["verify_password"];
      setLoading(true);
      api
        .newAccount(data)
        .then((response) => {
          if (response.status === 200) {
            window.open(`https://admin.jamfree.ca/login?email=${data.email}`);
            setLoading(false);
          }
        })
        .catch((error) => {
          alert("Error while creating your account.");
          setLoading(false);
        });
    }
  };
  const newAccountContent = require("../en.json");

  const loader = loading && (
    <Backdrop open={true} className={classes.backdrop}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
  return (
    <div>
      {loader}
      <form className={"form-container"} onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="row" justify="center">
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{ required: "Buisness or Organization name is required" }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <TextField
                    id="name"
                    margin="normal"
                    label="Buisness Name"
                    name="name"
                    fullWidth
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{ required: "Email is required." }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <TextField
                    margin="normal"
                    type="email"
                    label="Email"
                    name="email"
                    error={!!error}
                    helperText={error ? error.message : null}
                    fullWidth
                    {...field}
                    error={errors.email ? true : false}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => {
                return (
                  <TextField
                    label="Password"
                    margin="normal"
                    type="password"
                    name="password"
                    fullWidth
                    {...field}
                    error={errors.password ? true : false}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="verify_password"
              control={control}
              defaultValue=""
              render={({ field }) => {
                return (
                  <TextField
                    label="Verify Password"
                    margin="normal"
                    type="password"
                    fullWidth
                    error={errorPassword ? true : false}
                    helperText={errorPassword ? "Passwords dont match." : null}
                    {...field}
                  />
                );
              }}
            />
          </Grid>
          <input
            value="Get Started"
            className="btn-primary submit"
            type="submit"
          />
        </Grid>
      </form>
    </div>
  );
}
