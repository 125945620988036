import { Box } from "@material-ui/core";
import React from "react";

export default function MainHomeImage() {
  return (
    <div>
      <Box display={{ xs: "block", sm: "none" }}>
        <img
          src={process.env.PUBLIC_URL + "images/mobile-scanning-green-256.png"}
        />
      </Box>
      <Box display={{ xs: "none", sm: "block" }}>
        <img
          src={process.env.PUBLIC_URL + "images/mobile-scanning-green-512.png"}
        />
      </Box>
    </div>
  );
}
