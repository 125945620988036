import { Container, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import AdvantageContent from "./AdvantageContent";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: "20px",
  },
  imageContainer: {
    width: "100px",
    height: "100px",
    margin: "auto",
  },
}));
export default function Advantages() {
  const content = require("../en.json");
  const classes = useStyles();

  return (
    <Container className={classes.container} maxWidth="lg">
      <h2>Multi-prupose solution</h2>
      <p>Build, access anywhere, take action</p>
      <Grid justify="center" container direction="row">
        {content.advantages.map((item, index) => (
          <Grid
            lg={3}
            style={{ paddingTop: "20px", margin: "auto" }}
            item
            key={`advantages-${index}`}
          >
            <AdvantageContent
              title={item.title}
              description={item.description}
              src={item.src}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
