import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  makeStyles,
  withStyles,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import DoneIcon from "@material-ui/icons/Done";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  listItemText: {
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export default function FeatureList(props) {
  const { features } = props;
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");
  console.log("features: ", features);
  return (
    <div className="jamfree-features-list">
      <List dense={matches}>
        {features.map((text, index) => {
          return (
            <div key={`feature-${index}`}>
              {console.log(text)}
              {index !== 0 ? <Divider /> : null}
              <ListItem key={"item-" + index}>
                <ListItemIcon>
                  <DoneIcon style={{ fill: "#008060", fontWeight: "bolder" }} />
                </ListItemIcon>
                {text.tooltip ? (
                  <LightTooltip arrow title={text.tooltip}>
                    <ListItemText
                      primary={
                        <div className={classes.listItemText}>
                          {text.feature}
                        </div>
                      }
                    />
                  </LightTooltip>
                ) : (
                  <ListItemText
                    primary={
                      <div className={classes.listItemText}>{text.feature}</div>
                    }
                  />
                )}
              </ListItem>
            </div>
          );
        })}
      </List>
    </div>
  );
}
