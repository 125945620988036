import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import ContactUs from "../ContactUs";
import Home from "../Home";
import Navigation from "../Navigation";
import JamfreeForms from "../JamfreeForms";
import Marie from "../Marie";

export default function Routes() {
  return (
    <Router>
      <Navigation />
      <Switch>
        <Route path="/contact">
          <ContactUs />
        </Route>
        <Route path="/marie">
          <Marie/>
        </Route>
        <Route path="/forms/:form_id">
          <JamfreeForms />
        </Route>
        <Route path="/">
          <Home />
        </Route>
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}
