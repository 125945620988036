import { Grid } from "@material-ui/core";

const FormDivider = ({}) => {
  return (
    <Grid item xs={12}>
      {/* <Divider classes={{root:{fontWeight:"bolder"}}} variant="middle"  /> */}
      <hr className="solid" />
    </Grid>
  );
};
export default FormDivider;
