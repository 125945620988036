import { FormLabel, RadioGroup } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { Controller } from "react-hook-form";

const ReactHookRadio = ({
  name,
  label,
  control,
  defaultValue,
  children,
  required,
  ...props
}) => {
  const labelId = `${name}`;
  let rules = {}
  if (required){
    rules['required']= `${label} is required.`
  }
  return (
    <FormControl style={{textAlign:"left"}} fullWidth={true} {...props}>
      <FormLabel component={"legend"}>{label}</FormLabel>
      <Controller
        rules={rules}
        render={({ field, fieldState: { error }}) => {
          return (
              <RadioGroup {...field}>
                  {children}
              </RadioGroup>
          );
        }}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </FormControl>
  );
};
export default ReactHookRadio;
