import { Container, Grid } from '@material-ui/core'
import React from 'react'
import Header from './components/Header'
import Integration from './components/Integration';

export default function Integrations() {
    const integrations = require('./en.json').integrations;

    return (
        <Container className="jamfree-integrations" maxWidth="lg">
            <Grid container direction='column'>
                <Header />
                <Grid item>
                    <Grid container justify="center" direction="row">
                        {integrations.map((item, index) => <Integration src={item.src} />)}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}
