import accounts from "./accounts";
import entries from "./entries";
import forms from "./forms";
import stripe from "./stripe";

export default {
  getFormByAccountFormId: (account_id, form_id) => {
    return forms.getForm(account_id, form_id);
  },
  getProducts: async () => {
    return stripe.products();
  },
  newAccount: (data) => {
    return accounts.newAccount(data);
  },
  submitFormEntry: (data, account_id, form_id) => {
    return forms.submitFormEntry(account_id, form_id, data);
  },
  getForm:(form_id) => {return forms.getFormV2(form_id);},
  submitEntry:(entry) => { return entries.submit({entry: entry}); }
};
