import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { Controller } from "react-hook-form";
import MaterialUiPickersDate, { KeyboardDatePicker } from "@material-ui/pickers";
import { useState } from "react";
import {ReactDatePicker} from "react-datepicker";
import DatePicker from 'react-datepicker'

import "react-datepicker/dist/react-datepicker.css";

const ReactHookDatePicker = ({
  name,
  label,
  control,
  defaultValue,
  children,
  required,
  ...props
}) => {
  const labelId = `${name}`;
  let rules = {}
  if (required){
    rules['required']= `${label} is required.`
  }
  console.log(name, label)
  return (
    <div style={{textAlign:"left"}}>
    <FormControl>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <DatePicker
          label={label}
          id={labelId}
          placeholderText='Date of birth'
          onChange={(date) => field.onChange(date)}
          selected={field.value}
        />
    )}
    />
  </FormControl>
  </div>
  )
};
export default ReactHookDatePicker;
