import axios from "axios";
import Cookies from "js-cookie";

axios.interceptors.request.use(function (config) {
  if (Cookies.get("jamfreeToken")) {
    config.headers["X-Source"] = "web"
    return config;
  } else {
    return config;
  }
});

const requests = {
  get: (url) => {
    return axios.get(url);
  },
  post: (url, data) => {
    return axios.post(url, data);
  },
  put: (url, data) => {
    return axios.put(url, data);
  },
  delete: (url, data) => {
    return axios.delete(url, data);
  },
  postMedia: (url, data) => {
    return axios.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
export default requests;
