import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  RadioGroup,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { CheckBox } from "@material-ui/icons";
import { Controller } from "react-hook-form";

const ReactHookCheckBox = ({
  name,
  label,
  control,
  defaultValue,
  children,
  required,
  position,
  mainLabel,
  ...props
}) => {
  const labelId = `${name}`;
  let rules = {};
  if (required) {
    rules["required"] = `${label} is required.`;
  }
  return (
    <FormControl style={{ textAlign: "left" }} fullWidth={true} {...props}>
      {position === 0 ? <FormLabel>{mainLabel}</FormLabel> : null}
      <Controller
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <FormControlLabel control={<Checkbox {...field} />} label={label} />
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </FormControl>
  );
};
export default ReactHookCheckBox;
