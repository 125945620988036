import requests from "../../utils/http";
import jamfree from "../../utils/jamfree";

const formsEndpoint = "https://7nng2dg5xd.execute-api.us-east-1.amazonaws.com";

const forms = {
  getForm: (account_id, form_id) => {
    return requests
      .get(formsEndpoint + `/forms/${account_id}/${form_id}`)
      .then((response) => {
        if (response.status === 200) {
          return response.data.form;
        }
      });
  },
  submitFormEntry:(account_id, form_id, data) => {
    return requests.post(formsEndpoint+`/forms/${account_id}/${form_id}`,data)
  },
  getFormV2: (id) => {
    return jamfree.get(`forms/${id}`).then((response) => {
      if(response.status === 200){
        return response.data;
      }
    })
  }
};
export default forms;
